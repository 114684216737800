'use client';

import { Button } from '@mui/material';
import { LoginView } from '@/src/components/Auth/LoginView/LoginView';
import { SignupView } from '@/src/components/Auth/SignupView/SignupView';
import { useRightDrawerContext } from '@/src/contexts/useRightDrawerContext';
export const NavbarAnonymousButtons = () => {
  const {
    setActiveDrawer
  } = useRightDrawerContext();
  return <>
      <Button variant="outlined" sx={{
      height: 36,
      whiteSpace: 'nowrap'
    }} color="secondary" onClick={() => setActiveDrawer(<LoginView viewType="drawer" />)} data-sentry-element="Button" data-sentry-source-file="NavbarAnonymousButtons.tsx">
        Log in
      </Button>
      <Button variant="contained" sx={{
      height: 36,
      whiteSpace: 'nowrap'
    }} onClick={() => setActiveDrawer(<SignupView viewType="drawer" />)} data-sentry-element="Button" data-sentry-source-file="NavbarAnonymousButtons.tsx">
        Get started
      </Button>
    </>;
};