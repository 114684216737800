'use client';

import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { IconButton, Badge } from '@mui/material';
import { useGetNavbarGetDataRequest } from '@/src/components/Layouts/AppLayout/hooks/useGetNavbarGetDataRequest';
export const NavbarCartButton = () => {
  const {
    data: {
      cartPartCount = 0
    } = {}
  } = useGetNavbarGetDataRequest();
  return <IconButton color="secondary" href="/cart" title="Go to cart" data-sentry-element="IconButton" data-sentry-component="NavbarCartButton" data-sentry-source-file="NavbarCartButton.tsx">
      <Badge badgeContent={cartPartCount} color="secondary" data-sentry-element="Badge" data-sentry-source-file="NavbarCartButton.tsx">
        <ShoppingCartOutlinedIcon sx={{
        color: 'text.primary'
      }} data-sentry-element="ShoppingCartOutlinedIcon" data-sentry-source-file="NavbarCartButton.tsx" />
      </Badge>
    </IconButton>;
};