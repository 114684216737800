import { useQuery } from '@tanstack/react-query';
import currency from 'currency.js';
import { setCartValue } from '@qb/frontend/analytics/ga4';
import { webQueries } from '@qb/httpRequest/webQueries';

export const useGetNavbarGetDataRequest = () => {
  return useQuery({
    ...webQueries.cart.navbar.displayData,
    refetchOnWindowFocus: true,
    onSuccess: (data) => {
      setCartValue(currency(data.cartValue, { fromCents: true }));
    },
  });
};
